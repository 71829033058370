<template>
    <div class="activitys-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="activitys-list row g-2 mb-4">
                <div class="col-md-12 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search activity"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-auto col-lg-3">
                    <date-range-picker
                        ref="picker"
                        v-model="range"
                        opens="right"
                        time-picker
                        time-picker-24-hours
                        show-dropdowns
                        control-container-class="activity-table__date-range-picker"
                        :linked-calendars="false"
                        @update="changeDate"
                    >
                        <template #input>
                            <em class="fa fa-fw fa-calendar ml-n1"></em>
                            {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                            {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                            <strong class="caret"></strong>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-md-6 col-lg-2">     
                    <ui-select-multiple
                        id="activityStatusFilter"
                        name="activityStatusFilter"
                        label="Status"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="status"
                        v-model:value="filters.status"
                    />   
                </div>
                  <div class="col-md-6 col-lg-2">     
                    <ui-select-multiple
                        id="activityBrandsFilter"
                        name="activityBrandsFilter"
                        label="Brand"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="brand"
                        v-model:value="filters.brands"
                    />   
                </div>
                <div class="col-md-6 col-lg-2">     
                    <ui-select-multiple
                        id="activityRiskLevelFilter"
                        name="activityRiskLevelFilter"
                        label="Risk level"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="risk_level"
                        v-model:value="filters.risk_level"
                    />   
                </div>
                <div v-if="showDownloadButton && CAN(['read_reports'])" class="col-md-6 col-lg-2 ms-auto text-end">
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        :loading="downloadingReport"
                        @click="downloadReport()"
                    >
                        Download
                    </ui-button>
                </div>
            </div>
            <ui-table class="mb-4" :items="activitys" :fields="fields" :busy="busy" :scopes="['read_activity']">
                <template #cell(account)="data">
                    <span v-if="data.item.account">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.account.email" underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.account.phone" underline weight="regular" :link="`tel:+${data.item.account.phone}`">
                                {{ data.item.account.phone }}
                            </ui-link>
                        </div>
                        <ui-badge v-if="data.item.account.name" variant="success" class="align-self-start badge-large">{{ data.item.account.name }}</ui-badge>
                    </span>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(customer)="data">
                    <template v-if="data.item.customer">
                        <ui-link v-if="data.item.customer.id" weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.customer.id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.customer.first_name }} {{ data.item.customer.last_name || '' }}</span>
                        </ui-link>
                        <span v-else>
                            <span v-b-tooltip="'The account is not registered.'" class="fas fa-question-circle text-gray-500"></span>
                            {{ data.item.customer.first_name }} {{ data.item.customer.last_name || '' }}
                        </span>
                        <div>
                            <ui-link v-if="data.item.customer.email" underline weight="regular" :link="'mailto:' + data.item.customer.email">
                                <span>{{ data.item.customer.email }}</span>
                            </ui-link>
                            <ui-link v-else-if="data.item.customer.phone" underline weight="regular" :link="`tel:+${data.item.customer.phone}`">
                                {{ data.item.customer.phone }}
                            </ui-link>
                        </div>
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(threeds)="data">
                    <div v-if="data.item.threeds">
                        <ui-badge v-if="data.item.threeds.active" variant="success" class="align-self-start badge-large">
                            Yes
                        </ui-badge>
                        <ui-badge v-else variant="secondary" class="align-self-start badge-large">
                            No
                        </ui-badge>
                    </div>
                    <ui-badge v-else variant="secondary" class="align-self-start badge-large">
                        No
                    </ui-badge>
                </template>
                <template #cell(amount)="data">
                    {{ _currency(data.item.amount, data.item.currency, true) }}
                </template>
                <template #cell(card)="data">
                    <span class="text-capitalize" v-if="data.item.card">
                        {{ data.item.card.brand }} {{ data.item.card.last4 }}
                    </span>
                    <span class="text-capitalize" v-else>cash</span>
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>   
                <template #cell(risk_level)="data">
                    <ui-risk-level
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :risk_level="data.item.risk_level"
                        :risk_score="data.item.risk_score"
                    />
                </template>
                <template #cell(status)="data">
                    <ui-status
                        class="d-inline-flex align-items-center text-capitalize fs-13px"
                        :status="data.item.status"
                        :message="data.item.message"
                    />
                </template>     
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item :link="`/activitys/info?id=${data.item.id}`">
                            See details
                        </b-dropdown-item>
                        <b-dropdown-item @click.stop="sendToRisk(data.item.id)" v-if="CAN(['write_risks'])">
                            Send to risk
                        </b-dropdown-item>
                        <b-dropdown-item @click.stop="sendToWhitelist(data.item)" v-if="CAN(['write_whitelist'])">
                            Send to whitelist
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
        <download-report-snackbar ref="downloadReportSnackbar" @downloadFinished="downloadingReport = false" />
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';
import DownloadReportSnackbar from '@/components/reports/DownloadReportSnackbar.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiStatus from '@/components/ui/BadgeStatus';
import UiRiskLevel from '@/components/ui/BadgeRiskLevel';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';
import UiBadge from '@/components/ui/Badge';

import table_mixin from '@/mixins/tables.mixin';
import { filters as data_filters } from '@/data';

export default {
    components: {
        DateRangePicker,
        DownloadReportSnackbar,
        UiButton,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiStatus,
        UiRiskLevel,
        UiTable,
        UiTableSkeleton,
        UiBadge
    },
    mixins: [table_mixin],
    props: {
        accountId: {
            type: String,
            default: null,
            required: false,
        },
        accountSectionTable: {
            type: Boolean,
            default: false,
            required: false,
        },
        showDownloadButton: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            activitys: null,
            busy: false,
            creating: false,
            downloadingReport: false,
            fields: [
                { key: 'account', label: 'Account' },
                { key: 'customer', label: 'Customer' },
                { key: 'threeds', label: '3DS' },
                { key: 'amount', label: 'Amount' },
                { key: 'card', label: 'Method'},
                { key: 'created_at', label: 'Date' },
                { key: 'risk_level', label: 'Risk Level' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
        };
    },
    computed: {
        downloadLink() {
            let report_url = '/_/reports/activity';
            let search_params = new URLSearchParams();

            if (this.accountId) {
                search_params.append('account_id', this.accountId);
            }

            if (String(this.filters.q).trim() !== '') {
                search_params.append('q', this.filters.q);
            }

            if (this.filters.status) {
                search_params.append('status', this.filters.status);
            }

            if (this.filters.risk_level) {
                search_params.append('risk_level', this.filters.risk_level);
            }

            if (this.filters.range) {
                search_params.append('created_at[from]', this.$dayjs(this.filters.range.startDate).toISOString());
                search_params.append('created_at[to]', this.$dayjs(this.filters.range.endDate).toISOString());
            }

            return search_params ? `${report_url}?${search_params}` : report_url;
        },
        risk_level() {
            return data_filters.activity.risk_level;
        },
        status() {
            return data_filters.activity.status;
        },
        brand() {
            return data_filters.brands;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getActivitys);
            },
        }
    },
    mounted() {
        if (this.accountSectionTable) {
            this.fields = this.fields.filter(field => field.key !== 'account');
        }
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        downloadReport() {
            this.$refs.downloadReportSnackbar.download({name: 'Activity', url: this.downloadLink});
            this.downloadingReport = true;
        },
        async getActivitys() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    status: this.filters.status,
                    brand: this.filters.brands,
                    risk_level: this.filters.risk_level,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                if (this.accountId) {
                    params.account_id = this.accountId;
                }

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/activity', { params });

                this.total_pages = data.pages;
                this.activitys = data.docs;          
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async sendToRisk(id) {
            try {
                await this.$axios.post('/_/risks/', {
                    activity_id: id
                });
                this.$toast.success();
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
        async sendToWhitelist(data) {
            try {
                let payload = {
                    account: data.account?.email,
                    users: [ data.customer?.email ]
                };
                await this.$axios.post('/_/whitelist/create', payload);
                this.$toast.success('Customer added to Whitelist');
            } catch (err) {
                this.$toast.error(this.getErrorMessage(err));
            }
        }
    },
};
</script>

<style lang="scss">
.activity-table {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>

